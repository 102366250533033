import Vue from 'vue'
import citiesList from "@/config/citiesList.json"
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    let title = this.vm.$t('form_block.store.create')
    // @ts-ignore
    if (this.vm.$route.name == 'store-info') {
      title = this.vm.$t('form_block.store.update')
    }

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => title,
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
        save: {
          text: 'action.register',
          create: () => true
        },
      },
      blocks: [
        {
          title: title,
          help: (formInfo) => {
            return ''
          },
          disabled: (formInfo) => {
            //@ts-ignore
            if (vm.formStatus == 'create') return false
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId
          },
          data: {
            name: {
              required: true,
              label: 'store.data.name',
              type: 'liff-text',
              disabled: (formInfo) => {
                //@ts-ignore
                if (vm.formStatus == 'update') return true
                return false
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'store.data.member_name',
              type: 'liff-text',
              required: true,
              disabled: (formInfo) => {
                //@ts-ignore
                if (vm.formStatus == 'update') return true
                return false
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'store.data.member_phone',
              type: 'liff-text',
              required: true,
              maxlength: 10,
              disabled: (formInfo) => {
                //@ts-ignore
                if (vm.formStatus == 'update') return true
                return false
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            uniform_number: {
              label: 'data.uniform_number',
              type: 'liff-text',
              disabled: (formInfo) => {
                //@ts-ignore
                if (vm.formStatus == 'update') return true
                return false
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            city: {
              label: 'data.city',
              type: 'liff-selection',
              clearable: true,
              required: true,
              options: () => {
                return citiesList.map(item => ({
                  text: item.city,
                  value: item.city
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            district: {
              label: 'data.district',
              type: 'liff-selection',
              clearable: true,
              required: true,
              options: (formInfo) => {
                if (!formInfo.formData) return []
                const city = formInfo.formData.city
                if (!city) return []

                const targetCity = citiesList.find(item => {
                  return item.city == city
                })

                if (!targetCity) return []

                return targetCity.districts.map(district => ({
                  text: district.name,
                  value: district.name
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            address: {
              label: 'data-receive-address',
              type: 'liff-text',
              required: true,
              disabled: (formInfo) => {
                //@ts-ignore
                if (vm.formStatus == 'update') return true
                return false
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            // 'pivot.payment_type': {
            //   label: 'data.payment_type',
            //   required: true,
            //   type: 'liff-selection',
            //   clearable: true,
            //   options: (formInfo) => {
            //     //@ts-ignore
            //     const paymentTypes = vm.layoutProviderPaymentTypes
            //     return paymentTypes.map((type: any) => ({ text: vm.$t(`data.payment_type.${type}`), value: type }))
            //   },
            //   hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
            //     if (bothEmpty(originalData, data)) return false
            //     return window.eagleLodash.isEqual(originalData, data) === false
            //   },
            // },
            'pivot.comment': {
              label: 'store.data.comment',
              type: 'liff-textarea',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },

          },
        },
      ],
    }
  }

}

export default new formConfig()
